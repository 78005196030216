import { graphql } from "gatsby";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { Layout } from "../../components/layout/layout";
import {
  DesktopAuthorCard,
  MobileAuthorCard,
} from "../../components/cards/author-card";
import { PageHeading } from "../../components/layout/page-heading";
import FootprintsLayout from "../../components/layout/footprints-layout";

const CreativePiece = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const creativePiece = data.contentfulIllustration;
  return (
    <Layout
      title={creativePiece.title}
      description={creativePiece.description.description}
      image={creativePiece.creativePiece.fixed.src}
    >
      <FootprintsLayout>
        {isOpen && (
          <Lightbox
            mainSrc={creativePiece.creativePiece.fixed.src}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}

        <div className="flex my-6">
          <DesktopAuthorCard
            author={creativePiece.author}
            description={creativePiece.description.description}
            isWide
          />
          <main className="flex flex-col items-center flex-grow md:px-8 md:ml-8 text-center">
            <PageHeading title={creativePiece.title} />
            <MobileAuthorCard
              author={creativePiece.author}
              description={creativePiece.description.description}
            />
            <div className="mt-6 md:my-6 max-w-screen-md">
              <p className="italic">
                Click on the image to view it in fullscreen
              </p>

              <img
                className="w-full h-full object-center object-cover rounded-lg shadow-xl cursor-pointer"
                src={creativePiece.creativePiece.fixed.src}
                alt={creativePiece.description.description}
                onClick={() => setIsOpen(true)}
              />
            </div>
            <div className="md:hidden my-6">
              <p className="text-gray-700 font-semibold text-sm mt-8">
                {creativePiece.description.description}
              </p>
            </div>
          </main>
        </div>
      </FootprintsLayout>
    </Layout>
  );
};

export default CreativePiece;

export const query = graphql`
  query ($slug: String!) {
    contentfulIllustration(slug: { eq: $slug }) {
      title
      creativePiece {
        fixed(width: 1200) {
          width
          height
          src
          srcSet
        }
      }
      description {
        description
      }
      author {
        name
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        displayPicture {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }
      }
    }
  }
`;
